import { render, staticRenderFns } from "./ModalRubricNatureHistoricUpdate.vue?vue&type=template&id=79439358"
import script from "./ModalRubricNatureHistoricUpdate.vue?vue&type=script&lang=js"
export * from "./ModalRubricNatureHistoricUpdate.vue?vue&type=script&lang=js"
import style0 from "./ModalRubricNatureHistoricUpdate.vue?vue&type=style&index=0&id=79439358&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('79439358')) {
      api.createRecord('79439358', component.options)
    } else {
      api.reload('79439358', component.options)
    }
    module.hot.accept("./ModalRubricNatureHistoricUpdate.vue?vue&type=template&id=79439358", function () {
      api.rerender('79439358', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/router/views/cadastros-gerenciais/e-social/components/ModalRubricNatureHistoricUpdate.vue"
export default component.exports